import React from "react"
import { Link } from "gatsby"
import OpData from "./data/optionaloffer.json"
import Styles from "./css/optional.module.scss"
import {isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"
import Footerlinks from "@tightrope/footerlinks/footer"

class Optionaloffer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      pageData: {},
      data: OpData,
      siteData: {},
      displayff: 'none'
    }
    this.state.data.bullets = [];
  }
  openNewTab(){
    if (typeof window !== `undefined`) {
      let current_url = (new URL(window.location));
			if( navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ) {
					let nt = (current_url.href.indexOf('nt=') !== -1) ? current_url.searchParams.get("nt") : '' ;
					if(nt) {
						window.open(nt,"_self");
					}
			}
			else if( navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ) {
				window.location.hash = '#newtab';
			}
      window.dispatchEvent(new Event('openNativePage'));
    }
  }
  componentDidMount(){
    console.log(isFirefox());
    let data = this.state.data;
    if(this.props.data){
      data = this.props.data;
      let siteData = this.props.siteData;
      this.setState({data: data, siteData: siteData});
    }
    if(isChrome()){
      const browserData = Object.assign(data, data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(data, data.firefox);
      this.setState({data: browserData});
      this.setState({displayff: 'block'});
    }
  }

  render() {
    return (
      <>
      <div className={Styles.optionaloffer}>
        {/*-------Top-------*/}
        <div className={Styles.lcwsTop}>
          <div className={Styles.lcwsTopContainer}>
            <div className={Styles.boxlefttop}>
            <img src="/img/128yh.png" className={Styles.lcwsTopLogo} />
            <div className={Styles.brandtag}>{this.state.data.productname}</div>
            </div>
          </div>
        </div>
        {/*-------End of Top-------*/}
        {/*-------Main------*/}
        <div className={Styles.main}>
          <div className={Styles.offer}>
            <div className={Styles.offerLeft}>
              <h1 className={Styles.lead}>Optional Offer!</h1>

            <ul className={Styles.bullets}>
            {this.state.data.bullets != undefined ? this.state.data.bullets.map((bullet, i) => {
                return <li key={i}>{bullet}</li>
              }) : null}
              </ul>
            </div>
            <div className={Styles.offerRight}>
              <p>{this.state.data.subhead}</p>

              <button className={Styles.button}  data-cy="cta" onClick = {() => window.triggerInstall()}>Continue<span><svg width={30} height={30} viewBox="0 0 10 10" fill="none">
                    <path d="M5 7L1 3H9L5 7Z" fill="currentColor" />
                  </svg></span></button>
              <div className={Styles.skippy} id="nt" onClick = {() => this.openNewTab()}>
                No thanks
              </div>
              <div className={Styles.infotext}>
                <p>By installing {this.state.data.productname}, you acknowledge the <br /><a href="//search.freshy.com/terms-of-service.html" target="_blank">Terms of Service</a> and <a href="//search.freshy.com//privacy-policy/" target="_blank">Privacy Policy.</a></p>
              </div>
            </div>
          </div>
          {isChrome() ? <div className={Styles.ing}>
            <h1 className={Styles.thin}>How to Install from Chrome Web Store</h1>
            {/*---Steps for Chrome--*/}
            <div className={Styles.stepsContainer}>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step1Image}/>
                <div className={Styles.textGuide}>
                  <strong>Step1 :</strong>
                  Click 'Continue' button above to go to Chrome Web Store
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step2Image} />
                <div className={Styles.textGuide}>
                  <strong>Step2 :</strong>
                  Click 'Add to Chrome' button in Chrome Web Store
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step3Image}/>
                <div className={Styles.textGuide}>
                  <strong>Step3 :</strong>
                  Click 'Add extension' button in Chrome Web Store
                </div>
              </div>
            </div>
          </div>:null}

          {isFirefox() ? <div className={Styles.ing}>
            <h1 className={Styles.thin}>How to Install on Firefox</h1>
            {/*---Steps for Chrome--*/}
            <div className={Styles.stepsContainer}>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step1Image}/>
                <div className={Styles.textGuide}>
                  <strong>Step1 :</strong>
                  Click 'Continue to Installation' to begin install
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step2Image}/>
                <div className={Styles.textGuide}>
                  <strong>Step2 :</strong>
                  Click 'Add' to add the extension
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={this.state.data.step3Image}/>
                <div className={Styles.textGuide}>
                  <strong>Step3 :</strong>
                  Click 'Yes' to confirm
                </div>
              </div>
            </div>
          </div> :null}
        </div>
        <div className={Styles.iSearch} style={{display: this.state.displayff}}>
          <img src={this.state.siteData.searchBarImg} className={Styles.gray} />
          <p>{this.state.data.productname} sets your browser search to Yahoo.</p>
        </div>
        <Footerlinks></Footerlinks>
      </div>
</>
    )
  }
}

export default Optionaloffer
