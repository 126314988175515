import React from "react"
import { Link } from "gatsby"
import JSONData from "src/../branding/site-metadata.json"
import OpData from "./data/optionaloffer.json"
import Styles from "./css/optional.module.scss"
import {isChrome,isFirefox} from "../lpscripts/browserdetect"
import FooterLinks from "src/components/modules/footerlinks_language/footer.js"

class Optionalofferint extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      display: 'block',
      pageData: props.data,
      data: OpData,
      language: props.language
    }
    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(this.state.pageData){
      this.state.pageData.then(data => {
        const instanceData = Object.assign(OpData, data);
        this.setState({ data: instanceData});
        if(isChrome()){
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({data: browserData});
        }
        if(isFirefox()){
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({data: browserData});
        }
        this.setState({ language: this.props.language});
      });
    }
  }
  openNewTab(){
    if (typeof window !== `undefined`) {
      let current_url = (new URL(window.location));
			if( navigator.userAgent.toLowerCase().indexOf('firefox') > -1 ) {
					let nt = (current_url.href.indexOf('nt=') !== -1) ? current_url.searchParams.get("nt") : '' ;
					if(nt) {
						window.open(nt,"_self");
					}
			}
			else if( navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ) {
				window.location.hash = '#newtab';
			}
      window.dispatchEvent(new Event('openNativePage'));
    }
  }

  render() {
    return (
      <>
      <div className={Styles.optionaloffer}>
        {/*-------Top-------*/}
        <div className={Styles.lcwsTop}>
          <div className={Styles.lcwsTopContainer}>
            <div className={Styles.boxlefttop}>
              <img src={JSONData.headerico} className={Styles.lcwsTopLogo} />
            <div className={Styles.brandtag}>{this.state.data.productname}</div>
            </div>
          </div>
        </div>
        {/*-------End of Top-------*/}
        {/*-------Main------*/}
        <div className={Styles.main}>
          <div className={Styles.offer}>
            <div className={Styles.offerLeft}>
              <h1 className={Styles.lead}>{this.state.data.opoffer}</h1>

            <ul className={Styles.bullets}>
                <li>{this.state.data.bullet1}</li>
                <li>{this.state.data.bullet2}</li>
                { isFirefox() ?<li>{this.state.data.bullet3}</li>:null}
              </ul>
            </div>
            <div className={Styles.offerRight}>
              <p>{this.state.data.subhead}</p>

              <button className={Styles.button} onClick = {() => window.triggerInstall()}>{this.state.data.cta}<span><svg width={30} height={30} viewBox="0 0 10 10" fill="none">
                    <path d="M5 7L1 3H9L5 7Z" fill="currentColor" />
                  </svg></span></button>
              <div className={Styles.skippy} id="nt" onClick = {() => this.openNewTab()}>
                {this.state.data.nothanks}
              </div>
              <div className={Styles.infotext}>
                <p className={Styles.textGuide} dangerouslySetInnerHTML={{ __html: this.state.data.ctaexit }}></p>
              </div>
            </div>
          </div>
          {isChrome() ? <div className={Styles.ing}>
            <h1 className={Styles.thin}>{this.state.data.howto}</h1>
            {/*---Steps for Chrome--*/}
            <div className={Styles.stepsContainer}>
              <div className={Styles.stepChild}>
                <img src={OpData.step1Image}/>
                <div className={Styles.textGuide}>
                  {this.state.data.step1}
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={OpData.step2Image} />
                <div className={Styles.textGuide}>
                  {this.state.data.step2}
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={OpData.step3Image}/>
                <div className={Styles.textGuide}>
                  {this.state.data.step3}
                </div>
              </div>
            </div>
          </div>:null}

          {isFirefox() ? <div className={Styles.ing}>
            <h1 className={Styles.thin}>{this.state.data.howto}</h1>
            {/*---Steps for Chrome--*/}
            <div className={Styles.stepsContainer}>
              <div className={Styles.stepChild}>
                <img src={OpData.step1Image}/>
                <div className={Styles.textGuide} dangerouslySetInnerHTML={{ __html: this.state.data.step1 }}>
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={OpData.step2Image}/>
                <div className={Styles.textGuide} dangerouslySetInnerHTML={{ __html: this.state.data.step2 }}>
                </div>
              </div>
              <div className={Styles.stepChild}>
                <img src={OpData.step3Image}/>
                <div className={Styles.textGuide} dangerouslySetInnerHTML={{ __html: this.state.data.step3 }}>
                </div>
              </div>
            </div>
          </div> :null}
        </div>
        {/*-------End of Main-------*/}
        {/*search*/}
        {isFirefox()?<div className={Styles.iSearch}>
          <img src={OpData.searchBarImg} className={Styles.gray} />
          <p>{this.state.data.searchbartext}</p>
        </div>:null}
        {/*----Disclaimer--*/}
          <FooterLinks language={this.props.language}></FooterLinks>
      </div>
</>
    )
  }
}

export default Optionalofferint
