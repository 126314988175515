import {detect} from "detect-browser/es"

export function isChrome() {
  if(detect().name === 'chrome') {
    return true;
  }
  return false;
}

export function isFirefox(){
  if(detect().name === 'firefox') {
    return true;
  }
  return false;
}

export function isIeEdge(){
  if(detect().name === 'ie') {
    return true;
  }
  if(detect().name === 'edge') {
    return true;
  }
  if(detect().name === 'edge-chromium') {
    return true;
  }
  return false;
}
